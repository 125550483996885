<template>
  <b-modal
      class="wameed-lots-modal user-modal appcropper-modal"
      v-model="show"
      dialog-class="wameed-modal-md"
      title=""
      hide-footer
      hide-header
  >
    <div>
      <div class="d-flex justify-content-between" style="margin-bottom: 24px;">
        <h2 class="modal_title"> {{ $t("common.edit_image") }}</h2>
        <div @click="show = false" class="close-btn px-3 py-3">
          <close-icon/>
        </div>
      </div>
      <div id="result"></div>
      <div class="modal_container">
        <div>
          <h3 class="preview_title">{{ $t("common.preview") }}</h3>
          <div class="preview_container">
            <div id="cropper_preview" ref="previewRef" class="preview" dir="ltr">
              <img :src="croppedImage" alt="preview"/>
            </div>
          </div>
          <div class="preview_sizes">
            <span class="preview_sizes-ratio"> {{ getRatio() }} </span>

            <span class="preview_sizes-width"
                  :key="croppedImageWidth"
            >
              <span id="cropped_img_width">
                {{ croppedImageWidth }}
              </span>
              &times;
              <span id="cropped_img_height">
                {{ croppedImageHeight }}
                </span>

            </span>
          </div>
        </div>

        <div class="image_cropper_container" dir="rtl">
          <img
              id="cropper_image"
              ref="imageRef"
              class="image_cropper"
              :src="src"
              alt="Picture"
          />
        </div>
      </div>
      <div
          class="d-flex flex-wrap justify-content-end align-items-center"
          style="gap: 19px;margin-top: 30px"
      >
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="$t('btn.add')"
            type="submit"
            variant="main"
            @onClick="cropImage"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="show = false"
        />
      </div>
    </div>

  </b-modal>
</template>

<script>
import Cropper from "cropperjs";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 2
    },
    height: {
      type: Number,
      default: 1
    },
    options: {
      type: Object,
      default: () => ({})
    },
    currentItem: {
      type: Object,
      default: () => {
        return {
          id: null,
          title_ar: "",
          title_en: ""
        };
      }
    }
  },
  data() {
    return {
      show: this.visible,
      imageRef: "",
      previewRef: "",
      croppedImage: "",
      cropper: null,
      croppable: false,
      croppedImageWidth: "0px",
      croppedImageHeight: "0px"
    };
  },
  computed: {
    ratio() {
      return this.width / this.height;
    }
  },
  watch: {
    visible(val) {
      this.show = val;
    },
    show(val) {
      if (!val) {
        this.$emit("close");
      }
    }
  },
  methods: {
    getRatio() {
      let min = Math.min(this.width, this.height);

      if (min === 0) {
        return "";
      }
      let w = (this.width / min).toFixed(2);
      let h = (this.height / min).toFixed(2);

      w = w.replace(".00", "");
      h = h.replace(".00", "");

      return `${w}:${h} - `;
    },
    cropImage() {
      var croppedCanvas;

      // Crop
      croppedCanvas = this.cropper.getCroppedCanvas();
      console.log(croppedCanvas.toDataURL())
      this.$emit("onCrop", croppedCanvas.toDataURL());
    }
  },
  activated() {
    // cropper = new Cropper(imageRef.value, {
    //   aspectRatio: 1,
    //   viewMode: 1,
    //   ...props.options
    // });

    // cropper.crop();
  },
  created() {
    this.cropper = null;
    let timeout = 1000;
        console.log('uuuuuuuuuuuuuuuuuuuuuuu')
    setTimeout(() => {
      let image = document.getElementById("cropper_image"); //imageRef.value;

      if (this.cropper === null && image) {
        console.log('aaaaaaaaaaagefwraaaaaaaaaaaaaaaaaaa')
        let preview = document.getElementById("cropper_preview"); //previewRef.value;
        let clone = preview.getElementsByTagName('img')[0]
        let cropWidthEl = document.getElementById("cropped_img_width");
        let cropHeightEl = document.getElementById("cropped_img_height");
        let previewReady = false;
        this.cropper = new Cropper(image, {
          aspectRatio: this.width / this.height,
          viewMode: 1,
          autoCrop: true,

          dragMode: "move",
          ready: function () {
            this.croppable = true;
            // let clone = this.cloneNode();


            clone.className = "";
            clone.src = "";
            clone.style.cssText =
                "display: block;" +
                "width: 100%;" +
                "height: 100%;" +
                "object-fit: contain;" +
                "min-width: 0;" +
                "min-height: 0;" +
                "max-width: none;" +
                "max-height: none;";

            // preview.appendChild(clone.cloneNode());

            setTimeout(() => {
              previewReady = true;
              this.cropper.move(0, 0);
            }, 100);
          },
          crop: function (event) {
            if (!previewReady) {
              return;
            }
            console.log("this is crop event");
            var data = event.detail;
            var cropper = this.cropper;
            let croppedCanvas = cropper.getCroppedCanvas();
            let _croppedImage = croppedCanvas.toDataURL();
            this.croppedImage = _croppedImage;
            clone.src = _croppedImage;
            console.log(Math.floor(data.width) + "px")
            // get the cropped area
            cropWidthEl.innerText = Math.floor(data.width) + "px";
            cropHeightEl.innerText = Math.floor(data.height) + "px";
          }
        });
      }
    }, timeout);


  }
};
</script>


<style scoped>

img {
  max-width: 100%;
}

.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}

/* The css styles for `outline` do not follow `border-radius` on iOS/Safari (#979). */
.cropper-view-box {
  outline: 0;
  box-shadow: 0 0 0 1px #39f;
}

.row,
.preview {
  overflow: hidden;
  border-radius: 8px;
  background: #d9d9d9;
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.image_cropper {
  width: 437px;
  height: 328px;
  flex-shrink: 0;
  border-radius: 16px;
}

.preview_container {
  border-radius: 8px;
  border: 1px solid var(--grey-tulle-grey-200, #e8e9ea);
  background: var(--grey-tulle-grey-50, #f9f9fa);
  display: inline-flex;
  padding: 12px;
  align-items: flex-end;
  gap: 8px;
}

.preview_container img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.image_cropper_container {
  border-radius: 16px;
  border: 1px solid var(--grey-tulle-grey-200, #e8e9ea);
  background: var(--grey-tulle-grey-50, #f9f9fa);
  display: inline-flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
}

.preview_title {
  color: var(--grey-tulle-grey-1000-black, #1c1d1e);
  text-align: left;

  /* 16px/Medium */
  font-family: IBM Plex Sans Arabic;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.modal_title {
  color: var(--grey-tulle-grey-900, #383a3d);
  text-align: left;

  /* 24px/Medium */
  font-family: IBM Plex Sans Arabic;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
}

.modal_container {
  display: flex;
  direction: rtl;
  align-items: start;
  justify-content: end;
  gap: 24px;
  align-self: stretch;
}

.preview_sizes {
  text-align: center;
  /* 14px/Medium */
  font-family: IBM Plex Sans Arabic;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-top: 8px;
}

.preview_sizes-ratio {
  color: var(--brand-genestealer-purple-600, #8363aa);
}

.preview_sizes-width {
  color: var(--grey-tulle-grey-1000-black, #1c1d1e);
}
</style>